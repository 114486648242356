import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { AiFillGithub } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { RiComputerLine } from 'react-icons/ri';

const About = ({ isOpen, onClose }) => {
  const [isLessThan1372] = useMediaQuery('(max-width: 1372px)');
  const navigate = useNavigate();

  return (
    <>
      <Box w="100%">
       
      </Box>
    </>
  );
};
export default About;
